import * as React from "react";
import { SVGProps } from "react";

const SvgUpArrowAsh = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.414 5.414" {...props}>
    <g data-name="Group 4276">
      <path
        data-name="Path 587"
        d="m.706 4.706 4-4 4 4"
        fill="none"
        stroke={
          props.stroke
            ? props.stroke === "true"
              ? "#4f555e"
              : "#EAECF0"
            : "#4f555e"
        }
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgUpArrowAsh;
