export const environment = {
  domainRegexString:
    "^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\\.[a-zA-Z]{2,})+$",
  api: `${process.env.REACT_APP_DOMAIN_MIDDLE_LAYER}${process.env.REACT_APP_PORT_MIDDLE_LAYER_GATEWAY}/services/vcmeetingsettings/`,
  summaryDemo:"http://172.16.212.74:8080/services/vcmeetingsettings",

  fileServiceApi: `${process.env.REACT_APP_DOMAIN_MIDDLE_LAYER}${process.env.REACT_APP_PORT_MIDDLE_LAYER_GATEWAY}/services/file-service/`,

  organizationSettingsApi: `${process.env.REACT_APP_DOMAIN_MIDDLE_LAYER}${process.env.REACT_APP_PORT_MIDDLE_LAYER_GATEWAY}/services/organizationsettings/`,

  domainUrl: `${process.env.REACT_APP_DOMAIN_MIDDLE_LAYER}${process.env.REACT_APP_PORT_MIDDLE_LAYER_DEFAULT}`,

  homeUrl:
    `${process.env.REACT_APP_DOMAIN_MIDDLE_LAYER}${process.env.REACT_APP_PORT_MIDDLE_LAYER_DEFAULT}` +
    "/home",

  publicUrls: ["join/meeting"],

  meetingUrls: ["/join/meeting?="],

  // wpAdminUrl:
  //   `${process.env.REACT_APP_DOMAIN_WP_ADMIN}${process.env.REACT_APP_PORT_WP_ADMIN}` +
  //   "/",

  // meetingPanelUrl: `${process.env.REACT_APP_DOMAIN_MEETING_PANEL}` + "/",
  // templateUrl:
  //   `${process.env.REACT_APP_DOMAIN_TEMPLATE}${process.env.REACT_APP_PORT_TEMPLATE}` +
  //   "/",
  calenderUrl:
    `${process.env.REACT_APP_DOMAIN_MIDDLE_LAYER}${process.env.REACT_APP_PORT_MIDDLE_LAYER_DEFAULT}` +
    "/calender",
  mixpanelUrl: "https://api.mixpanel.com/",
  mixpanelToken: "694c28439fa4555649989f91148a74a2",
  gtmId: 'GTM-KM847FJ',
  fileDownloadUrl: "https://convay.com/services/file-service/file/downloadFile/b7fadd37-0fe3-42f2-9bf8-f0793f8f8b18.csv",
  mainDomain: `${process.env.REACT_APP_MAIN_DOMAIN}`,
  mainSubdomain: `${process.env.REACT_APP_MAIN_SUBDOMAIN}`

};
