import React, { useEffect } from "react";
import { Provider } from "react-redux";
import UpcomingMeetings from "./app/components/main/dashboard/upcoming-ongoing-events/upcoming-meetings/UpcomingMeetings";
import PortalItem from "./app/components/shared/portals/PortalItem";
import { store } from "./app/redux/store";
import Root from "./app/Root";
import TagManager from "react-gtm-module";
import { environment } from "./app/config/environments/environment";
import * as Sentry from "@sentry/react";


function App() {
  useEffect(() => {
    TagManager.initialize({
      gtmId: environment.gtmId,
    });
  }, []);

  return (
    <Provider store={store}>
      <Root />
    </Provider>
  );
}

export default Sentry.withProfiler(App);