import * as React from "react";
import { SVGProps } from "react";

const LeftArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20200907-tshirt-05.jpg"
    height="20200907-tshirt-05.jpg"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M0 0h24v24H0Z"
      style={{
        fill: "none",
      }}
    />
    <path
      d="M14.71 6.71a1 1 0 0 0-1.41 0L8.71 11.3a1 1 0 0 0 0 1.41l4.59 4.59a1 1 0 0 0 1.41-1.41L10.83 12l3.88-3.88a1 1 0 0 0 0-1.41Z"
      style={{
        fill: "#fff",
      }}
    />
  </svg>
);

export default LeftArrow;
