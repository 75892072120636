import React, { CSSProperties } from "react";
interface CopySnackIconProps {
  style?: CSSProperties;
}

function CopySnackIcon({ style }: CopySnackIconProps) {
  return (
    <div style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22.4"
        height="22.4"
        viewBox="0 0 22.4 22.4"
      >
        <path
          fill="#365d7e"
          stroke="#365d7e"
          strokeWidth="0.4"
          d="M3.435 18.318h.647v.647A3.235 3.235 0 007.318 22.2h11.647a3.235 3.235 0 003.235-3.235V7.318a3.235 3.235 0 00-3.235-3.235h-.647v-.648A3.235 3.235 0 0015.082.2H3.435A3.235 3.235 0 00.2 3.435v11.647a3.235 3.235 0 003.235 3.236zm15.53-12.942a1.941 1.941 0 011.941 1.941v11.648a1.941 1.941 0 01-1.941 1.941H7.318a1.941 1.941 0 01-1.941-1.941v-.647h9.706a3.235 3.235 0 003.235-3.235V5.376zM1.494 3.435a1.941 1.941 0 011.941-1.941h11.647a1.941 1.941 0 011.941 1.941v11.647a1.941 1.941 0 01-1.941 1.941H3.435a1.941 1.941 0 01-1.941-1.941z"
        ></path>
      </svg>
    </div>
  );
}

export default CopySnackIcon;