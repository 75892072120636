import * as React from "react";

function SvgClose(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.508"
      height="14.508"
      cursor="pointer"
      viewBox="0 0 14.508 14.508"
      {...props}
    >
      {/* <g
        fill="none"
        stroke="#6f7687"
        data-name="Group 653"
        transform="translate(-309.97 -22.97) translate(310.5 23.5)"
      > */}
      <g
        id="Group_653"
        data-name="Group 653"
        transform="translate(-309.97 -22.97)"
      >
        <line
          id="Line_214"
          data-name="Line 214"
          x2="13.448"
          y2="13.448"
          transform="translate(310.5 23.5)"
          fill="none"
          stroke="#365D7E"
          strokeLinecap="round"
          strokeWidth="0.75"
        ></line>
        <line
          id="Line_215"
          data-name="Line 215"
          y1="13.448"
          x2="13.448"
          transform="translate(310.5 23.5)"
          fill="none"
          stroke="#365D7E"
          strokeLinecap="round"
          strokeWidth="0.75"
        ></line>
      </g>
      {/* </g> */}
    </svg>
  );
}

export default SvgClose;
