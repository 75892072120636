import React from "react";

function Doc(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      //   width="25"
      //   height="32"
      viewBox="0 0 25 32"
      {...props}
    >
      <g data-name="Group 11580" transform="translate(-46.21 -69.837)">
        <path
          fill="#75b4e8"
          d="M3090.37 754.66h-12.929a6.09 6.09 0 01-6.035-6.108v-19.784a6.09 6.09 0 016.035-6.108h8.415l10.55 10.188v15.7a6.09 6.09 0 01-6.036 6.112z"
          data-name="Path 894"
          transform="translate(-3025.195 -652.823)"
        ></path>
        <rect
          width="12.282"
          height="2.431"
          fill="#fff"
          data-name="Rectangle 2575"
          rx="0.363"
          transform="translate(51.567 85.837)"
        ></rect>
        <rect
          width="6.785"
          height="2.431"
          fill="#fff"
          data-name="Rectangle 2576"
          rx="0.363"
          transform="translate(51.567 90.395)"
        ></rect>
      </g>
    </svg>
  );
}

export default Doc;
