import React, { useEffect, useRef } from "react";

interface AudioBarProps {
  audioStream: MediaStream | null;
}

function AudioBar({ audioStream }: AudioBarProps) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (audioStream) {
      const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
      const analyser = audioContext.createAnalyser();
      const source = audioContext.createMediaStreamSource(audioStream);

      analyser.fftSize = 256;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      analyser.connect(audioContext.destination);
      source.connect(analyser);

      const canvas = canvasRef.current;
      if (!canvas) return;

      const ctx = canvas.getContext("2d");

      // @ts-ignore
      // eslint-disable-next-line no-inner-declarations
      function draw() {
        analyser.getByteFrequencyData(dataArray);

        if (!ctx) return;

        // @ts-ignore
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        ctx.lineWidth = 2;
        ctx.strokeStyle = "#4285F4"; // Google Meet's microphone wave color

        ctx.beginPath();

        // @ts-ignore
        const sliceWidth = (canvas.width * 1.0) / bufferLength;
        let x = 0;

        for (let i = 0; i < bufferLength; i++) {
          const v = dataArray[i] / 128.0;
          // @ts-ignore
          const y = (v * canvas.height) / 2;

          if (i === 0) {
            ctx.moveTo(x, y);
          } else {
            ctx.lineTo(x, y);
          }

          x += sliceWidth;
        }

        // @ts-ignore
        ctx.lineTo(canvas.width, canvas.height / 2);
        ctx.stroke();

        requestAnimationFrame(draw);
      }

      draw();

      return () => {
        audioContext.close();
      };
    }
  }, [audioStream]);

  return (
      <canvas
          ref={canvasRef}
          width={250}
          height={50}
          style={{ border: "1px solid #ccc", marginTop: "5px" }}
      ></canvas>
  );
}

export default AudioBar;
