import * as React from "react";
import { SVGProps } from "react";

const Cross = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20200907-tshirt-05.jpg"
    height="20200907-tshirt-05.jpg"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.3 2.11a1 1 0 0 0 0-1.4 1 1 0 0 0-1.41 0L7 5.59 2.11.7A1 1 0 0 0 .7 2.11L5.59 7 .7 11.89a1 1 0 0 0 1.41 1.41L7 8.41l4.89 4.89a1 1 0 0 0 1.41-1.41L8.41 7l4.89-4.89Z"
      fill="#fff"
      fillOpacity={0.996}
    />
  </svg>
);

export default Cross;
