import * as React from "react";
import { SVGProps } from "react";

const SvgQuickActionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28.184}
    height={13.619}
    {...props}
  >
    <g data-name="Group 3903">
      <g
        data-name="Group 3649"
        fill="none"
        stroke="#9398a8"
        strokeLinecap="round"
        strokeWidth={2}
      >
        <path data-name="Line 761" d="M12.235 3.31H5" />
        <path data-name="Line 763" d="M12 11.31H8" />
        <path data-name="Line 762" d="M11.088 7.31H1" />
      </g>
      <g data-name="Group 3902" transform="translate(-1409.25 -131.19)">
        <rect
          data-name="Rectangle 2486"
          width={13.619}
          height={13.619}
          rx={6.81}
          transform="translate(1423.814 131.19)"
          fill="#9398a8"
        />
        <g
          data-name="Group 4696"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        >
          <path data-name="Line 802" d="m1430.624 138.315 2.439-2.586" />
          <path data-name="Line 803" d="M1430.624 138.315v1.957" />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgQuickActionIcon;
