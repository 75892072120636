import * as React from "react"

const CloseIcon = (props:any) => (
  <svg
    // className="cnv-toast-close"
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    {...props}
  >
    <g data-name="Group 653" fill="none" stroke="#6f7687" strokeLinecap="round">
      <path data-name="Line 214" d="m.53.53 13.448 13.448" />
      <path data-name="Line 215" d="M.53 13.978 13.978.53" />
    </g>
  </svg>
)

export default CloseIcon
