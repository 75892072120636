import * as React from "react"

const EditIcon = (props:React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
    <g data-name="Group 11065" transform="translate(-703.464 -180.304)">
      <rect
        data-name="Rectangle 2738"
        width={48}
        height={48}
        rx={8}
        transform="translate(703.464 180.304)"
        fill="#f4f8fb"
      />
      <g data-name="Component 5 \u2013 2">
        <g data-name="Group 1840">
          <path
            data-name="Path 176"
            d="m735.757 201.733-10.5 10.53-5.8.009.076-5.753 10.5-10.53a1.82 1.82 0 0 1 2.528-.293l3.492 3.5a1.834 1.834 0 0 1-.296 2.537Z"
            fill="none"
            stroke="#636d82"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.2}
          />
          <path
            data-name="Path 177"
            d="m719.491 210.205 2.23 2.23-2.163-.163Z"
            fill="#636d82"
          />
          <path
            data-name="Path 430"
            d="m731.429 202.765-2.867-2.867"
            fill="none"
            stroke="#636d82"
            strokeLinecap="round"
            strokeWidth={1.2}
          />
        </g>
      </g>
    </g>
  </svg>
)

export default EditIcon
