import React from "react";

function Filter() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="512"
            height="512"
            viewBox="0 0 512 512"
        >
            <path d="M449.265 174.563H62.735c-12.35 0-22.359-10.011-22.359-22.36s10.011-22.36 22.359-22.36h386.529c12.349 0 22.359 10.012 22.359 22.36 0 12.349-10.01 22.36-22.358 22.36zM387.482 278.361H124.518c-12.349 0-22.359-10.012-22.359-22.361s10.011-22.359 22.359-22.359h262.964c12.348 0 22.36 10.011 22.36 22.359.001 12.35-10.013 22.361-22.36 22.361zM334.01 382.158H177.99c-12.35 0-22.36-10.012-22.36-22.36 0-12.35 10.011-22.359 22.36-22.359h156.02c12.349 0 22.359 10.011 22.359 22.359s-10.011 22.36-22.359 22.36z"></path>
        </svg>
    );
}

export default Filter;
