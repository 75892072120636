import * as React from "react";
import { SVGProps } from "react";

const TimeZone = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.25 19.25" {...props}>
    <g data-name="Group 4257">
      <g data-name="Group 4252">
        <path
          data-name="Rectangle 2642"
          d="M4.277 0h10.7a4.282 4.282 0 0 1 4.273 4.277v10.7a4.282 4.282 0 0 1-4.277 4.277H4.277A4.282 4.282 0 0 1 0 14.973V4.277A4.282 4.282 0 0 1 4.277 0Zm10.7 18.3a3.33 3.33 0 0 0 3.323-3.327V4.277A3.33 3.33 0 0 0 14.973.95H4.277A3.33 3.33 0 0 0 .95 4.277v10.7A3.33 3.33 0 0 0 4.277 18.3Z"
          fill="#fcacac"
        />
      </g>
      <g data-name="Group 4253" fill="#fcacac">
        <path
          data-name="Path 14"
          d="M10.407 4.278h1.226a3.575 3.575 0 0 1 3.585 3.565v3.565a3.575 3.575 0 0 1-3.585 3.565H4.682l5.725-5.139Z"
        />
        <path
          data-name="Path 14 - Outline"
          d="M9.988 3.871h1.654a3.988 3.988 0 0 1 3.984 3.984v3.54a3.988 3.988 0 0 1-3.984 3.984H3.625l.6-.545 5.759-5.2Zm1.654 10.623a3.1 3.1 0 0 0 3.1-3.1V7.855a3.1 3.1 0 0 0-3.1-3.1h-.769v5.274l-4.946 4.465Z"
        />
      </g>
    </g>
  </svg>
);

export default TimeZone;
