import * as React from "react";

function SvgLeftNew(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path data-name="Path 15" d="M0 24V0h24v24z" fill="none" />
      <path
        data-name="Path 16"
        d="M8.71 12.71l4.59 4.59a1 1 0 001.41-1.41L10.83 12l3.88-3.88a1 1 0 10-1.41-1.41L8.71 11.3a1 1 0 000 1.41z"
        fill="#0a2463"
      />
    </svg>
  );
}

export default SvgLeftNew;
