import * as React from "react";

function SvgCreateSchedule(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 55.774 55.774"
      {...props}
    >
      <g data-name="Group 4283">
        <g data-name="Group 4281">
          <path
            data-name="Path 640"
            d="M0 27.887a27.887 27.887 0 1127.887 27.887A27.887 27.887 0 010 27.887z"
            fill="#ff9494"
          />
          <g
            data-name="Group 653"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth={2.2}
          >
            <path data-name="Line 214" fill="none" d="M15 27.721h26.122" />
            <path
              data-name="Path 431"
              d="M28.061 40.782V14.66"
              fill="#ff9494"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgCreateSchedule;
