import React from "react";

function InvitationSend() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="104.099"
            height="104.099"
            viewBox="0 0 104.099 104.099"
        >
            <defs>
                <linearGradient
                    id="linear-gradient1"
                    x1="0.07"
                    x2="0.967"
                    y1="0.938"
                    y2="0.029"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#d7dfe5"></stop>
                    <stop offset="1" stopColor="#365d7e"></stop>
                </linearGradient>
            </defs>
            <g transform="translate(-2.034 -2.436)">
                <path
                    fill="url(#linear-gradient1)"
                    d="M103.919 4.651A7.5 7.5 0 0096.2 2.829l-85.56 28.52a7.543 7.543 0 00.217 14.382L48.9 57.145a3.756 3.756 0 012.527 2.527l11.411 38.04a7.513 7.513 0 007.109 5.376h.118a7.516 7.516 0 007.153-5.159l28.521-85.559a7.5 7.5 0 00-1.82-7.721zM70.064 95.545L58.653 57.5a11.177 11.177 0 00-.689-1.564l22.175-22.172a3.771 3.771 0 10-5.334-5.334L52.63 50.606a11.169 11.169 0 00-1.563-.689L13.025 38.506 98.586 9.985zM8.473 82.8a3.771 3.771 0 11-5.334-5.334L22 58.605a3.771 3.771 0 015.334 5.334zm41.491-1.562a3.77 3.77 0 010 5.334L31.1 105.43a3.771 3.771 0 01-5.33-5.33l18.86-18.86a3.77 3.77 0 015.334 0zm-18.97 1.414l-18.731 18.987a3.772 3.772 0 11-5.371-5.3l18.731-18.985a3.772 3.772 0 115.371 5.3z"
                    data-name="Path 3256"
                ></path>
            </g>
        </svg>
    );
}

export default InvitationSend;