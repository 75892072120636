import React from "react";

function VideoBg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="45"
      viewBox="0 0 111.499 59.905"
    >
      <g data-name="Group 13257" transform="translate(-836.652 -1646.874)">
        <g data-name="Group 4108" transform="translate(155.348 1213.945)">
          <rect
            width="111.248"
            height="59.654"
            fill="#a8aebc"
            stroke="#a8aebc"
            strokeWidth="0.25"
            data-name="Rectangle 14"
            rx="8"
            transform="translate(681.43 433.054)"
          ></rect>
        </g>
        <rect
          width="111.248"
          height="59.654"
          fill="#eaecf0"
          stroke="#a8aebc"
          strokeWidth="0.25"
          data-name="Rectangle 14"
          rx="8"
          transform="translate(836.777 1647)"
        ></rect>
        <g data-name="Group 11412" transform="translate(-937.467 1391.6)">
          <g data-name="Group 4031" transform="translate(1820.523 278.58)">
            <g fill="#6f7687" data-name="Group 222">
              <path
                d="M1830.712 900.864v.412h-.011v6.787h.011v.434a2.978 2.978 0 01-3.1 2.826h-7.961a2.978 2.978 0 01-3.1-2.826v-7.633a2.981 2.981 0 013.1-2.837h7.961a2.981 2.981 0 013.1 2.837z"
                data-name="Path 63"
                transform="translate(-1816.544 -898.027)"
              ></path>
              <path
                d="M1831.149 900.82v7.723c0 .948-.927 1.427-1.459.743l-1.628-1.485v-6.272l1.628-1.474c.54-.675 1.459-.194 1.459.765z"
                data-name="Path 64"
                transform="translate(-1812.459 -898.027)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default VideoBg;
