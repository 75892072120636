import * as React from "react";

const ConvayHostCalendar = (props: any) => (
  <svg
    id="schedule-meeting"
    xmlns="http://www.w3.org/2000/svg"
    width={18.033}
    height={19.75}
    viewBox="0 0 18.033 19.75"
    ref={props.svgref}
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path fill="none" d="M0 0h7.52v8.441H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 11100" transform="translate(0 .75)">
      <rect
        data-name="Rectangle 1027"
        width={18.033}
        height={17.197}
        rx={3}
        transform="translate(0 1.804)"
        fill="#d3ffb1"
      />
      <g
        data-name="Repeat Grid 3"
        transform="translate(5.618 6.182)"
        clipPath="url(#a)"
      >
        <path
          data-name="Line 238"
          fill="none"
          stroke="#1cb1b0"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="m1 1.5-.124.123M6 1.5l-.124.123M1 4.5l-.124.123M6 4.5l-.124.123M1 7.5l-.124.123M6 7.5l-.124.123"
        />
      </g>
      <g
        data-name="Group 1551"
        fill="none"
        stroke="#1cb1b0"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path data-name="Line 235" strokeWidth={1.2} d="M5.049 0v3.606" />
        <path data-name="Line 236" strokeWidth={1.2} d="M13.705 0v3.606" />
        <path data-name="Line 258" strokeWidth={1.5} d="M13.705 0v.964" />
        <path data-name="Line 261" strokeWidth={1.5} d="M5.049 0v.964" />
      </g>
    </g>
  </svg>
);

export default ConvayHostCalendar;
