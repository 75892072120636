import React from "react";

function Pdf(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      //   width="25"
      //   height="32"
      viewBox="0 0 25 32"
      {...props}
    >
      <g data-name="Group 11583" transform="translate(-46.21 -226.136)">
        <g data-name="Group 4043" transform="translate(46.21 226.136)">
          <path
            fill="#fc8173"
            d="M3090.37 754.66h-12.929a6.09 6.09 0 01-6.035-6.108v-19.784a6.09 6.09 0 016.035-6.108h8.415l10.55 10.188v15.7a6.09 6.09 0 01-6.036 6.112z"
            data-name="Path 901"
            transform="translate(-3071.405 -722.66)"
          ></path>
        </g>
        <g
          fill="#fff"
          data-name="Group 4044"
          transform="translate(51.567 244.451)"
        >
          <path
            d="M3077.426 846.324h-2.021v6.586h1.128v-2.019h.893a2.284 2.284 0 100-4.567zm0 3.322h-.893v-2.077h.893a1.039 1.039 0 110 2.077z"
            data-name="Path 902"
            transform="translate(-3075.405 -846.32)"
          ></path>
          <path
            d="M3089.405 847.448v-1.128h-3.775v6.586h1.128v-2.729h2.158v-1.128h-2.158v-1.606z"
            data-name="Path 903"
            transform="translate(-3075.405 -846.32)"
          ></path>
          <path
            d="M3081.979 846.324h-1.629v6.586h1.629a3.309 3.309 0 000-6.587zm0 5.468h-.5v-4.349h.5a2.2 2.2 0 010 4.349z"
            data-name="Path 904"
            transform="translate(-3075.405 -846.32)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Pdf;
