import React, { useEffect, useRef, useState } from "react";
import styles from "./RecordCopyShare.module.css";
import { ConvayBeta, NewConvayLogo, Record } from "../../../../assets/icons";
import { useHistory, useParams } from "react-router-dom";
import { getRecordedMeetingDetailsById } from "../../../common/api-services/scheduleApi";
import { environment } from "../../../config/environments/environment";
import useJwtToken from "../../../config/auth/useJwtToken";
import isElectron from "is-electron";

function RecordCopyShare() {
  const { fileName, extension, size, length }: any = useParams();
  const [meetingInfo, setMeetingInfo] = useState<any>("");
  const [time, setTime] = useState<any>("");
  const videoRef = useRef<any>("");
  const [showMenuItems, setShowMenuItems] = useState(false);
  const history = useHistory();
  const authUser = useJwtToken();
  useEffect(() => {
    document.body.classList.add("bg-white");

    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);

  useEffect(() => {
    const regex = /^[0-9a-fA-F-]+/;
    const uuid = fileName.match(regex)[0];
    getRecordedMeetingDetailsById(uuid).then((resp: any) => {
      if (resp.data.status == "success") {
        setMeetingInfo(resp.data);
      }
    });
  }, []);
  // useEffect(() => {
  //   if (videoRef) {
  //     const minute = Math.floor((videoRef.current.duration % 3600) / 60)
  //       .toString()
  //       .padStart(2, "0");
  //     const hour = Math.floor(videoRef.current.duration % 60)
  //       .toString()
  //       .padStart(2, "0");
  //     setTime(`${minute}:${hour} ${Number(minute) > 1 ? "minutes" : "minute"}`);
  //   }
  // }, [videoRef]);
  function downloadFile() {
    const fileDownloadUrl =
      environment.fileServiceApi +
      "file/downloadFile/" +
      fileName +
      "." +
      extension;
    window.open(fileDownloadUrl, "_self");
  }

  return (
    <>
      {!authUser?.isAuthenticated || authUser?.isTokenExpired ? (
        <div className={"landing-page bg-white"}>
          <div className="cnv-wrapper-landing cnv-theme-default">
            {/*<div id="myheader" className={isSticky ? "header sticky" : "header"}>*/}
            <div id="myheader" className="header">
              <header className="cnv-header">
                <div className="cnv-container">
                  <nav className="cnv-navbar">
                    <div className="landing-logo">
                      <NewConvayLogo
                        width="150px"
                        height="54px"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          const userAgent = navigator.userAgent.toLowerCase();
                          if (userAgent.indexOf("electron/") > -1) {
                            console.log("from electron");
                          } else {
                            history.push("/");
                          }
                        }}
                      />
                    </div>
                    <a
                      href="#"
                      className="cnv-toggle-button"
                      onClick={() => setShowMenuItems(!showMenuItems)}
                    >
                      <span className="cnv-bar" />
                      <span className="cnv-bar" />
                      <span className="cnv-bar" />
                    </a>
                    <div
                      className={
                        showMenuItems
                          ? "cnv-navbar-links active"
                          : "cnv-navbar-links"
                      }
                      id="navi-list"
                    >
                      <ul>
                        <li className="nav-item">
                          <a className="nav-link" href="/m/j">
                            Join a Meeting
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href={isElectron() ? 'convay-meet://logout' : '/sign-in'}>
                            Sign In
                          </a>
                        </li>
                        {/* <li className="nav-item">
                                            <a href="/talk-to-sales" className="nav-link btn-talk-to-sale">
                                                Talk To Sales
                                            </a>
                                        </li> */}
                        <li className="nav-item">
                          <a href="/sign-up" className="nav-link btn-try">
                            Sign Up, It’s Free
                          </a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </header>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {meetingInfo ? (
        // <div className={`${styles.recordCopyContainer}`}>
        //   <div className={`${styles.recordCopyLogo}`}>
        //     <Record style={{ marginLeft: "80px", marginBottom: "30px" }} />
        //     <div className={`${styles.recordCopyLogoText}`}>
        //       Meeting Recording
        //     </div>
        //   </div>
        //   <div className={`${styles.recordMeetingInfo}`}>
        //     <div className={`${styles.recordMeetingTitle}`}>
        //       {meetingInfo?.meeting_title}
        //     </div>
        //     <div className={`${styles.recordMeetingTime}`}>
        //       {`${new Date(meetingInfo?.meeting_time).toLocaleString("en-US", {
        //         weekday: "long",
        //         month: "short",
        //         day: "numeric",
        //         year: "numeric",
        //       })}`}
        //       ,{" "}
        //       {`${new Date(meetingInfo?.meeting_time).toLocaleString("en-US", {
        //         hour: "numeric",
        //         minute: "numeric",
        //         hour12: true,
        //       })} - ${new Date(meetingInfo?.ending_time).toLocaleString(
        //         "en-US",
        //         {
        //           hour: "numeric",
        //           minute: "numeric",
        //           hour12: true,
        //         }
        //       )}`}
        //     </div>
        //   </div>
        //   <div className={`${styles.recordFileInfo}`}>
        //     <div className={`${styles.recordTitle}`}>
        //       {fileName}.{extension}
        //     </div>
        //     <div className={`${styles.recordTimeInfo}`}>
        //       <div className={`${styles.recordLength}`}>
        //         Length: {length} minutes
        //       </div>
        //       <div className={`${styles.bar}`}>|</div>
        //       <div className={`${styles.recordSize}`}>{size} mb</div>
        //     </div>
        //     <div className={`${styles.recordDownloadButton}`}>
        //       <div className="cnv-form-group">
        //         <input
        //           type="submit"
        //           className={`signUpSubmitBtn`}
        //           name="sign_up_submit"
        //           value="Download"
        //           onClick={() => downloadFile()}
        //         />
        //       </div>
        //     </div>
        //   </div>
        //   <video
        //     ref={videoRef}
        //     style={{ display: "none" }}
        //     id="myvid"
        //     src={
        //       environment.fileServiceApi +
        //       "file/downloadFile/" +
        //       fileName +
        //       "." +
        //       extension
        //     }
        //   />
        // </div>
        <div className={`${styles.recordCopyContainer}`}>
          <div>
            <div className={`${styles.recordLogoCenter}`}>
              <Record />
            </div>
            <div className={`${styles.recordCopyLogoText}`}>
               Meeting Recording
            </div>

            <div className={`${styles.recordMeetingTitle}`}>
              {meetingInfo?.meeting_title}
            </div>
            <div className={`${styles.recordMeetingTime}`}>
              {`${new Date(meetingInfo?.meeting_time).toLocaleString("en-US", {
                weekday: "long",
                month: "short",
                day: "numeric",
                year: "numeric",
              })}`}
              ,{" "}
              {`${new Date(meetingInfo?.meeting_time).toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })} - ${new Date(meetingInfo?.ending_time).toLocaleString(
                "en-US",
                {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                }
              )}`}
            </div>
            <div className={`${styles.recordTitle}`}>
              {fileName}.{extension}
            </div>
            <div className={`${styles.recordTimeInfo}`}>
              <div className={`${styles.recordLength}`}>
                Length: {length} minutes
              </div>

              <div className={`${styles.bar}`}>|</div>
              <div className={`${styles.recordSize}`}>{size} mb</div>
            </div>
            <div className="cnv-form-group" style={{marginTop:"15px"}}>
               <input
                  type="submit"
                  className={`signUpSubmitBtn`}
                  name="sign_up_submit"
                  value="Download"
                  onClick={() => downloadFile()}
                />
              </div>
          </div>
        </div>
      ) : (
        <div className={`${styles.recordCopyContainer}`}>
          No recording found with this link
        </div>
      )}
    </>
  );
}

export default RecordCopyShare;
