import * as React from "react"

const ActionDeleteIcon = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    {...props}
  >
    <g
      data-name="Group 4028"
      fill="none"
      stroke="#ff9494"
      strokeLinecap="round"
      strokeWidth={1.2}
    >
      <path
        data-name="Rectangle 2398"
        d="M2.57 2.856h12.522v12.529a2 2 0 0 1-2 2H4.57a2 2 0 0 1-2-2V2.856h0Z"
      />
      <path data-name="Line 782" d="M.6 2.856h16.501" />
      <path data-name="Line 783" d="M6.604.6h4.454" />
      <path data-name="Line 784" d="M5.535 6.169v7.403" />
      <path data-name="Line 785" d="M8.831 6.169v7.403" />
      <path data-name="Line 786" d="M12.014 6.169v7.403" />
    </g>
  </svg>
)

export default ActionDeleteIcon
