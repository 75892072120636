import React from "react";

export default function PlusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.996"
      height="15.996"
      viewBox="0 0 15.996 15.996"
    >
      <path
        fill="#1c9c91"
        d="M17.218 3H4.777A1.777 1.777 0 003 4.777v12.441A1.777 1.777 0 004.777 19h12.441A1.783 1.783 0 0019 17.218V4.777A1.783 1.783 0 0017.218 3zm-2.666 8.886h-2.666v2.666a.889.889 0 01-1.777 0v-2.666H7.443a.889.889 0 110-1.777h2.666V7.443a.889.889 0 011.777 0v2.666h2.666a.889.889 0 010 1.777z"
        data-name="Path 21634"
        transform="translate(-3 -3)"
      ></path>
    </svg>
  );
}
