import * as React from "react";

function SvgArrowUp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path data-name="Path 15" d="M0 0h24v24H0z" fill="none" />
      <path
        data-name="Path 16"
        d="M11.29 8.71L6.7 13.3a1 1 0 001.41 1.41L12 10.83l3.88 3.88a1 1 0 101.41-1.41L12.7 8.71a1 1 0 00-1.41 0z"
        fill="#0a2463"
      />
    </svg>
  );
}

export default SvgArrowUp;
