import React from "react";

function Google(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.296"
      height="38.296"
      viewBox="0 0 38.296 38.296"
      {...props}
    >
      <path
        fill="#fbbb00"
        d="M8.487 151.069l-1.333 4.976-4.872.1a19.181 19.181 0 01-.141-17.88l4.338.8 1.9 4.312a11.428 11.428 0 00.107 7.694z"
        data-name="Path 3206"
        transform="translate(0 -127.926)"
      ></path>
      <path
        fill="#518ef8"
        d="M280.02 208.176a19.141 19.141 0 01-6.826 18.509l-5.463-.279-.773-4.827a11.412 11.412 0 004.91-5.827h-10.24v-7.575h18.392z"
        data-name="Path 3207"
        transform="translate(-242.059 -192.605)"
      ></path>
      <path
        fill="#28b446"
        d="M59.361 320.345a19.154 19.154 0 01-28.853-5.858l6.205-5.079a11.388 11.388 0 0016.41 5.831z"
        data-name="Path 3208"
        transform="translate(-28.227 -286.266)"
      ></path>
      <path
        fill="#f14336"
        d="M57.853 4.408l-6.2 5.078a11.387 11.387 0 00-16.787 5.962l-6.238-5.107a19.152 19.152 0 0129.225-5.933z"
        data-name="Path 3209"
        transform="translate(-26.483)"
      ></path>
    </svg>
  );
}

export default Google;
