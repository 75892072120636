import React from "react";

function downloadNew(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22.899 22.899"
      {...props}
    >
      <g
        fill="none"
        stroke="#afb3bb"
        strokeLinecap="round"
        strokeWidth="1.6"
        data-name="Group 11576"
        transform="translate(-807.589 -4428.096)"
      >
        <rect
          width="21.699"
          height="21.699"
          data-name="Rectangle 2582"
          rx="4"
          transform="translate(808.188 4428.696)"
        ></rect>
        <path
          d="M819.037 4445.528l-5.776-5.235h11.552z"
          data-name="Path 911"
        ></path>
        <path
          d="M0 6.426L0 0"
          data-name="Line 820"
          transform="translate(819.038 4433.747)"
        ></path>
      </g>
    </svg>
  );
}

export default downloadNew;
