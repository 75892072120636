import React from "react";

function CameraSmall() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.014"
      height="10.815"
      viewBox="0 0 12.014 10.815"
    >
      <g
        fill="none"
        stroke="#a8acb6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        data-name="Group 12816"
        transform="translate(-246.334 -1513.242)"
      >
        <path
          d="M248.523 1513.792h4.8a1.792 1.792 0 011.641 1.91v5.895a1.791 1.791 0 01-1.641 1.91h-4.8a1.791 1.791 0 01-1.641-1.91h0v-5.892a1.792 1.792 0 011.639-1.913z"
          data-name="Rectangle 290"
        ></path>
        <path
          d="M255.467 1517.673l1.609-1.676c.267-.387.726-.113.726.434v4.434c0 .55-.46.822-.728.433l-1.609-1.69"
          data-name="Path 242"
        ></path>
      </g>
    </svg>
  );
}

export default CameraSmall;
