import * as React from "react";
import { SVGProps } from "react";

const Tickash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.53"
    height="17.349"
    viewBox="0 0 25.53 17.349"
    className={props.from === "content" ? "" : "cnv-modal-close"}
    {...props}
  >
    <g transform="translate(.39 .39)">
      <path
        fill="none"
        stroke="#6f7687"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
        d="M.6 8.491l7.891 7.768L24.15.6"
        data-name="Path 897"
      ></path>
    </g>
  </svg>
);

export default Tickash;
