import React from "react";

function Img(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      //   width="25"
      //   height="32"
      viewBox="0 0 25 32"
      {...props}
    >
      <g data-name="Group 11581" transform="translate(-46.21 -121.769)">
        <path
          fill="#7bcc83"
          d="M3090.37 754.66h-12.929a6.09 6.09 0 01-6.035-6.108v-19.784a6.09 6.09 0 016.035-6.108h8.415l10.55 10.188v15.7a6.09 6.09 0 01-6.036 6.112z"
          data-name="Path 895"
          transform="translate(-3025.195 -600.891)"
        ></path>
        <path
          fill="#fff"
          stroke="rgba(0,0,0,0)"
          strokeWidth="1"
          d="M51.568 145.765l3.1-5.628a1.263 1.263 0 012.135-.036l3.421 5.664"
          data-name="Path 896"
        ></path>
        <path
          fill="#fff"
          stroke="rgba(0,0,0,0)"
          strokeWidth="1"
          d="M57.758 145.765l3.425-3.69a.876.876 0 011.325.048l2.921 3.642"
          data-name="Path 897"
        ></path>
        <circle
          cx="1.888"
          cy="1.888"
          r="1.888"
          fill="#fff"
          stroke="rgba(0,0,0,0)"
          strokeWidth="1"
          data-name="Ellipse 7546"
          transform="translate(57.727 135.003)"
        ></circle>
      </g>
    </svg>
  );
}

export default Img;
