import * as React from "react";

function SchedularDate(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 19.903"
      {...props}
    >
      <defs>
        <clipPath id="schedularDate_svg__a">
          <path fill="none" d="M0 0h9.482v5.418H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 3990">
        <g
          data-name="Rectangle 1027"
          transform="translate(0 2.294)"
          fill="none"
          stroke="#ff9494"
          strokeWidth={1.2}
        >
          <rect width={20} height={17.61} rx={4} stroke="none" />
          <rect x={0.6} y={0.6} width={18.8} height={16.41} rx={3.4} />
        </g>
        <path
          data-name="Line 235"
          fill="none"
          stroke="#ff9494"
          strokeLinecap="round"
          strokeWidth={1.2}
          d="M5.77.6v3.387"
        />
        <path
          data-name="Line 236"
          fill="none"
          stroke="#ff9494"
          strokeLinecap="round"
          strokeWidth={1.2}
          d="M15 .6v3.387"
        />
        <path
          data-name="Line 237"
          fill="none"
          stroke="#ff9494"
          strokeWidth={1.2}
          d="M.339 8.05h19.323"
        />
        <g
          data-name="Repeat Grid 3"
          transform="translate(6.574 11.098)"
          clipPath="url(#schedularDate_svg__a)"
        >
          <path
            data-name="Line 238"
            fill="none"
            stroke="#ff9494"
            strokeWidth={1.2}
            d="M0 .5h1M5 .5h1M0 3.5h1M5 3.5h1"
          />
        </g>
      </g>
    </svg>
  );
}

export default SchedularDate;
