import React from "react";

function HomeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.4"
      height="22.402"
      viewBox="0 0 22.4 22.402"
    >
      <g data-name="9-Home" transform="translate(-1.796 -1.794)">
        <path
          fill="#020405"
          stroke="#020405"
          strokeWidth="0.4"
          d="M17.567 23.991H8.424a3.927 3.927 0 01-4.069-3.739v-4.9a.786.786 0 111.571 0v4.9a2.356 2.356 0 002.5 2.168h9.143a2.356 2.356 0 002.5-2.168v-4.9a.786.786 0 111.571 0v4.9a3.927 3.927 0 01-4.069 3.739zm6.2-10.439a.785.785 0 000-1.115L13.553 2.227a.785.785 0 00-1.115 0L2.227 12.437a.789.789 0 001.115 1.115L13 3.892l9.653 9.661a.785.785 0 001.115 0z"
          data-name="Path 2412"
        ></path>
      </g>
    </svg>
  );
}

export default HomeIcon;
