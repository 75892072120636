import React from "react";

function MeetingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.596"
      height="22"
      viewBox="0 0 18.596 22"
    >
      <path
        fill="#fff"
        d="M16.451 6.762l.863-.863A.858.858 0 0016.1 4.686l-.863.863a9.21 9.21 0 00-5.082-2.1V1.717h.83a.858.858 0 000-1.717H7.61a.858.858 0 000 1.717h.83v1.727a9.3 9.3 0 108.012 3.318zM9.3 20.283a7.581 7.581 0 117.579-7.583A7.59 7.59 0 019.3 20.283zm3.975-11.558a.858.858 0 010 1.214L9.9 13.309A.858.858 0 018.691 12.1l3.37-3.371a.858.858 0 011.214-.004zm0 0"
      ></path>
    </svg>
  );
}

export default MeetingIcon;
