import React from "react";

function OrgLeftArrow(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30.65"
      height="21.078"
      viewBox="0 0 30.65 21.078"
      {...props}
      //   style={...props}
    >
      <g data-name="Group 11936" transform="translate(-140.001 -80.961)">
        <g
          data-name="arrow,-left,-back,-previous"
          transform="translate(135.415 72.668)"
        >
          <path
            fill="#05090d"
            d="M15.125 8.293l1.934 1.934-7.237 7.237h25.414V20.2H9.822l7.237 7.238-1.934 1.934-10.539-10.54z"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default OrgLeftArrow;
