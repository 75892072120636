import React from "react";

function CancelOrg(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.986"
      height="16.94"
      viewBox="0 0 16.986 16.94"
      {...props}
    >
      <g transform="translate(.1 .099)">
        <g data-name="Group 12077" transform="translate(0 .001)">
          <path
            fill="#365d7e"
            stroke="#365d7e"
            strokeWidth="0.2"
            d="M7.445 8.37L.191 1.116a.654.654 0 01.925-.925L8.37 7.445 15.624.191a.654.654 0 01.925.925L9.295 8.37l7.254 7.254a.654.654 0 01-.925.925L8.37 9.299l-7.254 7.249a.654.654 0 01-.925-.925z"
            data-name="Path 3247"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default CancelOrg;
