import React from "react";

function MultipleDelete() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <g fill="none" fillRule="evenodd" stroke="#4A4A4A">
                <path d="M5.5 7.5V20A1.5 1.5 0 007 21.5h11a1.5 1.5 0 001.5-1.5V7.5h-14z"></path>
                <path
                    strokeLinecap="round"
                    d="M8.5 10.41v8.18m4-8.18v8.18m4-8.18v8.18M9 4.333V3.244C9 2.557 9.627 2 10.4 2h4.2c.773 0 1.4.557 1.4 1.244v1.09"
                ></path>
                <rect width="18" height="3" x="3.5" y="4.5" rx="1.5"></rect>
            </g>
        </svg>
    );
}

export default MultipleDelete;
