import React from "react";

function Show(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36.573"
      height="22.4"
      viewBox="0 0 36.573 22.4"
      {...props}
    >
      <g
        fill="none"
        stroke="#767676"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.4"
        data-name="Group 12236"
        transform="translate(-2.869 -10.32)"
      >
        <path
          d="M21.156 11.521A19.873 19.873 0 004.167 21.25a.516.516 0 000 .543 19.7 19.7 0 0033.977 0 .516.516 0 000-.543 19.844 19.844 0 00-16.988-9.729z"
          data-name="Path 3264"
        ></path>
        <path
          d="M26.156 21.521a5 5 0 11-5-5 5 5 0 015 5z"
          data-name="Path 3265"
        ></path>
      </g>
    </svg>
  );
}

export default Show;
