import * as React from "react"

const ErorrIcon = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    {...props}
  >
    <g data-name="Group 11128" transform="translate(-1433.69 -519.5)">
      <rect
        data-name="Rectangle 2642"
        width={16.177}
        height={16.177}
        rx={4}
        transform="translate(1434.19 520)"
        fill="none"
        stroke="#ff9494"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g data-name="Group 11129">
        <g data-name="Group 11130" transform="translate(0 -1.505)">
          <circle
            data-name="Ellipse 7576"
            cx={1}
            cy={1}
            r={1}
            transform="translate(1441.279 531.897)"
            fill="#d98282"
          />
          <g
            data-name="Group 4246"
            fill="none"
            stroke="#ff9494"
            strokeLinecap="round"
          >
            <path
              data-name="Line 825"
              strokeLinejoin="round"
              d="M1442.279 528.291v-3"
            />
            <path data-name="Path 923" d="M1442.279 528.291v1.664" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default ErorrIcon
