import * as React from "react"
import { SVGProps } from "react"

const ConvayHostSendInvitation = (props: any) => (
  <svg
    data-name="Group 11099"
    xmlns="http://www.w3.org/2000/svg"
    width={19.494}
    height={18.208}
    viewBox="0 0 19.494 18.208"
    ref={props.svgref}
    {...props}
  >
    <g data-name="Component 242 \u2013 1">
      <rect
        data-name="Rectangle 2111"
        width={19.494}
        height={18.208}
        rx={4}
        fill="#d3ffb2"
      />
    </g>
    <path
      data-name="Path 783"
      d="m17.561 4.877-7.813 5.52-7.812-5.52"
      fill="none"
      stroke="#1cb1b0"
      strokeLinecap="round"
      strokeWidth={1.4}
    />
  </svg>
);

export default ConvayHostSendInvitation
