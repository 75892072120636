import * as React from "react";

function SvgTick(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={props.height}
      width={props.width}
      {...props}
    >
      <path data-name="Path 70" d="M0 0h24v24H0z" fill="none" />
      <path
        data-name="Path 71"
        d="M9 16.2l-3.5-3.5a.99.99 0 10-1.4 1.4l4.19 4.19a1 1 0 001.41 0L20.3 7.7a.99.99 0 00-1.4-1.4z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgTick;
